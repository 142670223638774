.AboutMe {
  background-color:transparent;
  height: 83vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 1vh;
  color: white;
}

.AboutMe-img {
  display: inline-block;
  position: relative;
  width: 30vh;
  height: 30vh;
  overflow: hidden;
  border-radius: 50%;
}

.AboutMe-img img {
  width: auto;
  height: 120%;
  margin-left: -14vh;
  margin-top: -2vh;
  border: black 1px;
}

.AboutMe-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vh;
  height: 50%;
  overflow: scroll;
  flex-grow: 1;
}

.AboutMe-profile {
  margin: 0;
  padding: 0 10vw;
  font-size: 1.5rem;
  overflow: scroll;
  max-height: 100%;
  align-self: baseline;
}

.App {
  display: flex;
  height: 100vh;
  flex-direction: column;
  background-color: RGB(12, 38, 79, 1);
  color: white;
}

.Main {
  background-color: transparent;
  height: 87vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;

}

.NavBar {
  height: 10vh;
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, .08);
  align-items: center;
  justify-content: flex-end;
  padding: 0;
  color: white;
  margin: 1vh;
  border-radius: 1vh;
}

.NavItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
  margin: 0 4vh;
  height: 9vh;
  width: 12vw;
  font-size: 5vh;
  color: white;
}

.NavItem button {
  height: 100%;
  width: 100%;
  background: transparent;
  font-size: 3vh;
  margin: 0;
  padding: 0;
  color: white;
  font-weight: bold;
}

.NavItem-dd {
  display: block;
  flex-direction: column;
  align-items: center;
  margin: 0 1vh 0 1vh;
  height: 9vh;
  width: 12vw;
  font-size: 2vh;
  z-index: 100;
}

.NavItem-dd button {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  font-size: 3vh;
}

.NavItem-dd ul {
  padding: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, .08);
  display: flex;
  flex-direction: column;
  margin: .5vh auto;
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
}

.NavItem-dd ul li {
  display: flex;
  flex-grow: 1;
  padding: 2vh 0 1vh 0;
  width: 100%;
  margin: auto;
}

.NavItem-dd ul li a {
  padding: 0;
  width: 100%;
  height: 100%;
}

.NavItem-dd ul li a img {
  display: block;
  padding: 0;
  width: 7vw;
  margin: auto;
}

.NavItem p {
  justify-self: center;
  margin: auto;
}

.Title {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  z-index: 1;
  height: 100%;
}

.Title-footer {
  color: white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  flex-grow: 1;
  font-style: italic;
}

.Title-footer p{
  color: white;
  margin: 0;
}


.Title-header {
  font-size: calc(70px + 2vmin);
  color: white;
  width: 100%;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 5vh;
}

.Title-sub-header {
  font-size: calc(10px + 2vmin);
}

a {
  width: 100%;
  height: 100%;
  margin: 0 10px 0 10px;
}

button {
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  font-size: 2vh;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

li {
  display: block;
}